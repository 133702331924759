import React, { useState } from 'react';
import { Button, TextField, Box, Modal, Typography, CircularProgress } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import 'pages/Form/Form.scss';
import { writeUserData, getTcPdfUrl, getCapitalTcPdfUrl } from 'setup/dbwrites/index.js';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

export default function Form({ formType }) {
    const [checked, setChecked] = useState(false);
    const [capitalChecked, setCapitalChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        number: '',
    });
    const [pdfUrl, setPdfUrl] = useState('');
    const [searchParams] = useSearchParams();
    const formTypeFromQuery = searchParams.get('type');


    const textFields = [
        { id: 'first-name', label: 'First Name', variant: 'outlined', name: 'firstName' },
        { id: 'last-name', label: 'Last Name', variant: 'outlined', name: 'lastName' },
        { id: 'email', label: 'Email', variant: 'outlined', name: 'email' },
        { id: 'number', label: 'Cell_Number', variant: 'outlined', name: 'number' },
    ];
  

    const handleCheckboxChange = (event) => setChecked(event.target.checked);
    const handleCapitalCheckboxChange = (event) => setCapitalChecked(event.target.checked); 
    const handleInputChange = (event) => setFormData({ ...formData, [event.target.name]: event.target.value });
    
    const handleOpenModal = (pdfType) => async (e) => {
        e.preventDefault(); 
        e.stopPropagation(); 
        const url = pdfType === 'tc' ? await getTcPdfUrl() : await getCapitalTcPdfUrl();
        setPdfUrl(url);
        setOpen(true);
    };
    const handleCloseModal = () => setOpen(false);

    const handleAlert = (message) => {
        setAlertMessage(message);
        setAlertOpen(true);
    };

    const handleSubmit = async () => {
        // Check if either checkbox is not checked
        if (!checked || !capitalChecked) {
            handleAlert('Please accept all terms and conditions to continue.');
            return; // Stop the function from proceeding further
        }

        // New check for empty text fields
        if (!formData.firstName || !formData.lastName || !formData.email) {
            handleAlert('Please fill in all the text fields.');
            return; // Stop the function from proceeding further
        }

        setLoading(true); // Show loading indicator
        try {
            const success = await writeUserData(formData, checked, capitalChecked);
            setLoading(false); // Hide loading indicator

            if (success) {
                handleAlert('Data successfully saved!');
        
                // Navigate based on form type extracted from URL
                if (formTypeFromQuery === 'Vip') {
                    navigate('/Vip', { state: { nextStep: '4AMSubmit', productType: 'Vip' } });
                } else if (formTypeFromQuery === 'Gold') {
                    navigate('/Gold', { state: { nextStep: '4AMSubmit', productType: 'Gold' } });
                } else if (formTypeFromQuery === 'Plat') {
                    navigate('/Plat', { state: { nextStep: '4AMSubmit', productType: 'Plat' } });
                } else {
                    // Default navigation if formType is undefined or not matched
                    navigate('/submit-success');
                }
            } else {
                handleAlert('Failed to save data. Please try again.');
            }
        } catch (error) {
            // Log and display any error that occurred during data saving
            console.error('Failed to save form data:', error);
            setLoading(false); // Ensure loading indicator is hidden on error
            handleAlert('An error occurred. Please try again.');
        }
    };

    return (
        <div className="container">
            <div className='mainDiv'>
                <Box className='titlebox'>
                    Terms of Service Form
                </Box>
                <Box className='box'>
                    {textFields.map((field, index) => (
                        <TextField
                            key={index}
                            id={field.id}
                            label={field.label}
                            variant={field.variant}
                            name={field.name}
                            value={formData[field.name]}
                            onChange={handleInputChange}
                            style={{ margin: 8 }}
                            required
                        />
                    ))}

                    {/* <Box className='labelBox' onClick={handleOpenModal('tc')}>
                        4am Club T and C's
                    </Box> */}
                    <FormControlLabel
                        className='pt-1'
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleCheckboxChange}
                                name="checked"
                                color="primary"
                            />
                        }
                        label={
                            <span>
                                I have read and accept the <span className="tc-link" onClick={(e) => handleOpenModal('tc')(e)}>4am Club's Terms and Conditions</span>
                            </span>
                        }
                    />

                    {/* <Box className='labelBox' onClick={handleOpenModal('capital')}>
                        4am Capital T and C's and disclosure
                    </Box> */}

                    <FormControlLabel
                        className='pt-1'
                        control={
                            <Checkbox
                                checked={capitalChecked}
                                onChange={handleCapitalCheckboxChange}
                                name="capitalChecked"
                                color="primary"
                            />
                        }
                        label={
                            <span>
                                I have read and accept the <span className="tc-link" onClick={(e) => handleOpenModal('capital')(e)}>4am Capital Terms and Conditions and Disclosure </span>
                            </span>
                        }
                    />

                    <Button className='button' variant='outlined' onClick={handleSubmit} disabled={!checked || !capitalChecked}>
                        Continue
                    </Button>
                </Box>

                <Modal
                    open={open}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="modalStyle">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Terms and Conditions
                        </Typography>
                        {pdfUrl && <iframe src={pdfUrl} title="T&C Document" style={{ width: '100%', height: '400px' }} frameBorder="0"></iframe>}
                    </Box>
                </Modal>

                <Modal
                open={alertOpen}
                onClose={() => setAlertOpen(false)}
                aria-labelledby="alert-modal-title"
                aria-describedby="alert-modal-description"
            >
                <Box className="modalStyle">
                    <Typography id="alert-modal-title" variant="h6" component="h2">
                        Alert
                    </Typography>
                    <Typography id="alert-modal-description" sx={{ mt: 2 }}>
                        {alertMessage}
                    </Typography>
                    <Button onClick={() => setAlertOpen(false)}>Close</Button>
                </Box>
            </Modal>

                <Modal
                    open={loading}
                    // Disable closing on outside click or Esc key for the loading modal
                    onClose={() => {}}
                    aria-labelledby="loading-modal-title"
                    aria-describedby="loading-modal-description"
                >
                    <Box className="modalStyle">
                        <CircularProgress /> {/* Loading indicator */}
                        <Typography id="loading-modal-title" sx={{ mt: 2 }}>
                            Busy uploading...
                        </Typography>
                    </Box>
                </Modal>

                {/* <AMFooter /> */}
            </div>
        </div>
    );
}
