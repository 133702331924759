import AMFooter from "components/4AMComponents/4AMFooter";
import AMHeader from "components/4AMComponents/4AMHeader";
import React from "react";
import { Outlet } from "react-router-dom";
import "setup/routing/4AMRouting/index.scss";

const AMRouting = () => {
  return (
    <div className="app">
      <main className="content">
        {/* Render the child components using Outlet */}
        <AMHeader />
        <Outlet />
        <AMFooter />
      </main>
    </div>
  );
};

export default AMRouting;
