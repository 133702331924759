import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'pages/Vid/vid.scss';
import { Button, Box, CircularProgress } from '@mui/material';

export default function Vid() {
  const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const { nextStep, productType } = location.state || {}; // State to manage video loading

  // This will be called immediately as an example, but in a real app, you might want to tie this to an actual loading state.
  const handleLoad = () => {
    setIsLoading(false); // Set loading state to false to hide the loader
  };

  const handelSubmit = () => {
    const url = 'https://www.xmza.com/register/profile-account?lang=en&utm_source=4amclub.co.za&utm_content=1214566&utm_medium=affiliate';
    window.open(url, '_blank');
  };


  const handleNextStep = () => {
    if (nextStep && productType) {
        navigate(`/${nextStep}?type=${productType}`);
    }
};

  return (
    <div className='mainDiv'>
      <Box className='box'>
        <Box className='titlebox'>
         How to setup Copytrading 
        </Box>
{/* 
        <Box className='titlebox'>
          Copy Trading Password: 151228
        </Box>
        <Box>
          (Note: Password will change)
        </Box> */}

        {isLoading ? (
          <Box className='loadingBox' display="flex" justifyContent="center" alignItems="center">
            <CircularProgress /> {/* Show loading indicator */}
          </Box>
        ) : null}
        <iframe
          src="https://www.youtube.com/embed/Nei96NiKCc0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          onLoad={handleLoad}
          style={{ display: isLoading ? 'none' : 'block' }}
        />
        

        <Box className='bbox'> 
          <Button className='button' onClick={handelSubmit}>Set up your XM</Button>
        </Box>

        
        <Box className='titlebox'>
         How to Setup Your swiss BDD 
        </Box>


        {isLoading ? (
          <Box className='loadingBox' display="flex" justifyContent="center" alignItems="center">
            <CircularProgress /> 
          </Box>
        ) : null}
        <iframe
          src="https://www.youtube.com/embed/_KZYjvJiLTI" 
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          onLoad={handleLoad}
          style={{ display: isLoading ? 'none' : 'block' }}
        />


        <Box className='bbox'> 
          <Button className='button' onClick={handelSubmit}>Set up Swiss BDD</Button>
        </Box>

        <Box>
        (If you have followed all the steps in the videos above please move on to step 2)
        </Box>

      <Box className='tan'>
        <Box className='titlebox'>
          
        Step 2 :

        </Box>
        

        <Box className='bbox'> 
          <Button className='button' onClick={handleNextStep}>Go to step 2</Button>
        </Box>
     </Box>

      </Box>
    </div>
  );
}
