import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

export default function Dashboard() {
  const [rows, setRows] = React.useState([
    { id: 1, col1: 'Hello', col2: 'World' },
    { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
    { id: 3, col1: 'MUI', col2: 'Rocks' },
  ]);

  const [columns] = React.useState([
    { field: 'col1', headerName: 'Column 1', width: 150, editable: true },
    { field: 'col2', headerName: 'Column 2', width: 150, editable: true },
  ]);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
