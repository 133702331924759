import { db } from 'setup/firebase/index.js'; 
import { doc, setDoc,collection, addDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const writeUserData = async (userData, tcAccepted, capitalTcAccepted) => {
    const timestamp = new Date(); 
    const userRef = doc(db, "clients", userData.email);
    try {
        await setDoc(userRef, { ...userData, tcAccepted, capitalTcAccepted, timestamp });
        return true;
    } catch (error) {
        console.error("Error writing document: ", error);
        return false;
    }
};

const getPdfUrl = async (folderName, fileName) => {
    const storage = getStorage();
    const pdfRef = ref(storage, `${folderName}/${fileName}`);
    try {
        const url = await getDownloadURL(pdfRef);
        return url;
    } catch (error) {
        console.error("Error getting PDF URL: ", error);
        return null;
    }
};

const getTcPdfUrl = () => getPdfUrl("T&C", "4AM Club Terms and Conditions.pdf"); 
const getCapitalTcPdfUrl = () => getPdfUrl("Capital", "4AM Capital Terms and Conditions and Disclosures 2.pdf"); // Same here


const writeSupportData = async (supportData) => {
    const timestamp = new Date();
    const supportCollectionRef = collection(db, "support");
    try {
        const docRef = await addDoc(supportCollectionRef, { ...supportData, timestamp });
        console.log("Document written with ID: ", docRef.id); 
        return true;
    } catch (error) {
        console.error("Error adding document: ", error);
        return false;
    }


    
};


const writeSupportAgent = async (userId, agentName) => {
    const userRef = doc(db, "support", userId); 
    try {
      await setDoc(userRef, { supportAgent: agentName }, { merge: true });
      return true;
    } catch (error) {
      console.error("Error updating document: ", error);
      return false;
    }
  };
  
  export { writeUserData, getTcPdfUrl, getCapitalTcPdfUrl, writeSupportData, writeSupportAgent };