import React, { useEffect } from 'react';
import { db } from 'setup/firebase/index.js'; // Make sure to import db from your Firebase setup file
import { collection, getDocs } from 'firebase/firestore';

export default function DbCalls({ setUserData }) {
  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "support"));
      const usersArray = [];
      querySnapshot.forEach((doc) => {
        
        const data = doc.data();
        const formattedDate = data.timestamp ? new Date(data.timestamp.seconds * 1000).toLocaleString() : 'No Date'; // Adjust format as needed
        usersArray.push({ id: doc.id, ...data, timestamp: formattedDate });
      });
      setUserData(usersArray);
    };

    fetchData();
  }, [setUserData]);

  return null; 
}
