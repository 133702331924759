
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";



const firebaseConfig = {
  apiKey: "AIzaSyAuu66WtOmNtlR2Z4isrxQgb1EXygxisxU",
  authDomain: "ambookings-9beab.firebaseapp.com",
  projectId: "ambookings-9beab",
  storageBucket: "ambookings-9beab.appspot.com",
  messagingSenderId: "734122836368",
  appId: "1:734122836368:web:156c8bbf64308af570f315",
  measurementId: "G-FDGV1RYEHZ"
};


 const app = initializeApp(firebaseConfig);
export const db = getFirestore(app); 

export default app