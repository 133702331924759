import React, { useState } from 'react';
import { Box, Button, TextField, Modal, Typography } from '@mui/material'; // Add Modal and Typography to your imports
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import 'pages/Ticket/Ticket.scss';
import { InputAdornment } from '@mui/material';



export default function Ticket() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    // number: '',
    problemType: '',
    problemDescription: ''
  });

  const [countryCode, setCountryCode] = useState('');
  const [modalOpen, setModalOpen] = useState(false); 
  const [modalMessage, setModalMessage] = useState(''); 

  const handleChange = (field, event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleSubmit = async () => {
   
    const updatedNumber = formData.number.startsWith('0') ? formData.number.slice(1) : formData.number;
    
    const updatedFormData = { ...formData, number: `+${countryCode}${updatedNumber}` };

    setModalOpen(true); 
    setModalMessage('Submitting ticket...'); 
    const { writeSupportData } = await import('setup/dbwrites/index'); 
    const success = await writeSupportData(updatedFormData);
    if (success) {
        setModalMessage('Your ticket was logged and support will reach out as soon as possible.');
    } else {
        setModalMessage('An error occurred while saving support information. Please try again.');
    }
    setTimeout(() => setModalOpen(false), 3000); 
};
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className='mainDiv'>
      <Box className='box'>
        Ticket
      </Box>
      <Box className='boxL'>
        <Box>
          {[
            { id: 'firstName', label: 'First Name' },
            { id: 'lastName', label: 'Last Name' },
            // { id: 'number', label: 'Number' },
          ].map((field) => (
            <TextField
              key={field.id}
              id={field.id}
              label={field.label}
              value={formData[field.id]}
              onChange={(event) => handleChange(field.id, event)}
              margin="normal"
            />
          ))}
        </Box>

        <Box className='boxA' style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
                id="country-code"
                label="Country Code"
                value={countryCode}
                onChange={(event) => setCountryCode(event.target.value)}
                margin="normal"
                style={{ width: '25%', marginRight: '1rem' }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">+</InputAdornment>,
                }}
                inputProps={{
                    maxLength: 4, // Limit number of characters; adjust as needed
                }}
            />
            <TextField
                id="number"
                label="Number"
                value={formData.number}
                onChange={(event) => handleChange('number', event)}
                margin="normal"
                style={{ width: '75%' }}
            />
        </Box>


        <Box className='boxA'>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Type Of Problem</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData.problemType}
              label="Type Problem"
              onChange={(event) => handleChange('problemType', event)}
            >
              <MenuItem value="V1">V1</MenuItem>
              <MenuItem value="V2">V2</MenuItem>
              <MenuItem value="V3">V3</MenuItem>
              <MenuItem value="Websites Login">Websites Login</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box className='boxA'>
          <TextField
            id="outlined-multiline-static"
            label="Please Describe Your Problem"
            multiline
            rows={4}
            value={formData.problemDescription}
            onChange={(event) => handleChange('problemDescription', event)}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box className='boxB'>
          <Button variant='outlined' onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Box>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Ticket Submission
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalMessage}
          </Typography>
        </Box>
      </Modal>
    </div>
 
    
  );
}
