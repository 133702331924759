import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Modal, Box } from '@mui/material';
import DbCalls from 'setup/dbCalls/dbCalls.js'; // Adjust the path as necessary
import { writeSupportAgent } from 'setup/dbwrites/index.js'; // Adjust the path as necessary

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AdminDash() {
  const [userData, setUserData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState('');

  const handleOpen = (description) => {
    setSelectedDescription(description);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const updateSupportAgent = async (userId, agentName) => {
    const updated = await writeSupportAgent(userId, agentName);
    if (updated) {
      setUserData(prev =>
        prev.map(user =>
          user.id === userId ? { ...user, supportAgent: agentName } : user
        )
      );
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'firstName', headerName: 'Name', width: 150 },
    { field: 'lastName', headerName: 'Email', width: 150 },
    { field: 'number', headerName: 'Number', width: 150 },
    { field: 'problemType', headerName: 'Problem Type', width: 150 },
    {
      field: 'problemDescription',
      headerName: 'Problem Description',
      width: 150,
      renderCell: (params) => (
        <button onClick={() => handleOpen(params.value)}>View</button>
      ),
    },
    { field: 'timestamp', headerName: 'Time Submitted', width: 150 },
    {
      field: 'supportAgent',
      headerName: 'Support Agent',
      width: 150,
      renderCell: (params) => (
        <select
          defaultValue={params.value || ""}
          onChange={(e) => {
            updateSupportAgent(params.id, e.target.value);
          }}
          style={{ width: '100%' }}
        >
          <option value="">None</option>
          <option value="Marinus">Marinus</option>
          <option value="Vian">Vian</option>
          <option value="Devan">Devan</option>
        </select>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DbCalls setUserData={setUserData} />
      <DataGrid
        rows={userData}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="problem-description-title"
        aria-describedby="problem-description-content"
      >
        <Box sx={modalStyle}>
          <h2 id="problem-description-title">Problem Description</h2>
          <p id="problem-description-content">{selectedDescription}</p>
        </Box>
      </Modal>
    </div>
  );
}
