import Submit from "components/4AMComponents/4AMSubmit";
// import React from "react";
import {
  useSearchParams,
} from "react-router-dom";
export default function Submit4AM() {
  const [queryParameters] = useSearchParams();
//   console.log(queryParameters.get("type"));
  const type = queryParameters.get("type");
  if (type === "Gold") {
    console.log("awe");

    return Submit(["Gold"]);
  }
  if (type === "Plat") {
    console.log("V2");

    return Submit(["Plat"]);

  
  }
  // if (type === "bdd") {
  //   console.log("BDD");

  //   return Submit(["BDD"]);
  // }
  // if (type === "v1v2") {
  //   console.log("v1v2");

  //   return Submit(["V1","V2"]);
  // }
  // if (type === "v1v2bdd") {
  //   console.log("v1v2bdd");

  //   return Submit(["V1","V2","BDD"]);
  // }
  // if (type === "xm") {
  //   console.log("xm");

  //   return Submit(["xm"]);
  // }
  if (type === "Vip") {
    console.log("vip");

    return Submit(["Vip"]);
  }
  
}
