import * as React from "react";
import { useState } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { Select, MenuItem, Box } from "@mui/material";
import { collection, doc, getDocs, getFirestore, updateDoc } from "firebase/firestore";
import app from "pages/login/index.js"; // Ensure this points to your Firebase config file
import Popup from 'reactjs-popup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'; // Assuming you want buttons in your dialog
import { Await } from "react-router-dom";
import "pages/login/index.scss";

export default function Dashboard() {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [selcetedUser, setselectedUser] = useState(null);
  const [selcetedValue, setselectedValue] = useState(null);

  class Myform extends React.Component{
    handelChange = (event) =>{

      const seletedOption = event.target.value;
      this.setState({

        seletedOption:seletedOption,
        message: 'you have selceted an installer '

      })

    }


  }

  function formatDate(date) {
    return date.toLocaleDateString("en-US", {
      weekday: "short", // "Wed"
      year: "numeric", // "2024"
      month: "short", // "Feb"
      day: "2-digit", // "28"
    });
  }
  React.useEffect(() => {
    const fetchUsers = async () => {
      try {
        const db = getFirestore(app); // Initialize Firestore database
        const querySnapshot = await getDocs(collection(db, "user")); // Fetch all documents from the 'users' collection
        const users = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          // Only include users who have an 'installer' field
          if (!data.installer) {
            return {
              id: doc.id,
              name: data.name || "No Name",
              surname: data.surname || "No Surname",
              cellNumber: data.cellNumber || "No Cell Number",
              products: data.products || "No Products",
              prefTime: data.prefTime || "No Preferred Time",
              prefDate: data.TimeStamp || "No Preferred Date",
              assign: data.installer, // Include the installer field
            };
          }
          return null;
        }).filter(user => user !== null); // Remove any null users (those without an installer)
        setRows(users);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
      setLoading(false);
    };
  
    fetchUsers();
  }, []);

  const handelPopupOpen = () => {
      setOpen(true);
  }


  const handleClosePopup = () =>{
    setOpen(false)
  }


 

  const handleAssignChange = (event, params) => {

    console.log(`Assign ${params.row.id} to ${event.target.value}`);
    setselectedUser(params.id)
    setselectedValue(event.target.value)
    handelPopupOpen();
      
  };


  const send =  async() =>{

        const db = getFirestore(app); 
        const docRef = doc(db,"user",selcetedUser)
         try{
 
           await updateDoc (docRef,{
 
             installer:selcetedValue
 
           })
 
           console.log("Upload sucessfull")
 
         }catch(error){
 
           console.log("Upload failed")
         }

       window.location.reload();  
   }

  

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "surname", headerName: "Surname", width: 130 },
    { field: "cellNumber", headerName: "Cell Number", width: 150 },
    { field: "products", headerName: "Products", width: 150 },

    {
      field: "prefDate",
      type: "datetime",
      valueGetter: (params) => new Date(params.row.prefDate.seconds * 1000),
      valueFormatter: ({ value }) => formatDate(value),
      minWidth: 250,
    },

    { field: "prefTime", headerName: "Preferred Time", width: 130 },
    {
      field: "assign",
      headerName: "Assign",
      width: 160,
      renderCell: (params) => (
        <Select
          labelId="assign-select-label"
          id="assign-select"
          value={params.value}
          onChange={(event) => handleAssignChange(event, params)}
          size="small"
          fullWidth
        >
          <MenuItem value="Marinus">Assign to Marinus</MenuItem>
          <MenuItem value="Vian">Assign to Vian</MenuItem>
          <MenuItem value="Devin">Assign to Devin</MenuItem>
        </Select>
      ),
    },
  ];


  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid rows={rows} columns={columns} pageSize={5} loading={loading} />    
    
    <Box >

          <dialog
          open={open}
          onClose={handleClosePopup}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="dialog"
          >
            <DialogTitle id="scroll-dialog-title">
             Are you want to assign this user ?
            </DialogTitle>

            <DialogContent className="dialog-content">
              
               <Button onClick={send} className="myCustomButton">
                  Yes 
               </Button>

               <Button onClick={handleClosePopup} className="noButton">
                  No 
               </Button>

            </DialogContent>

          </dialog>


    </Box>    
    
    </div>
  );
}
