import React, { useState, useEffect } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { getFirestore, collection, getDocs, addDoc } from "firebase/firestore";
import app from "setup/firebase/index.js";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import styles from "components/4AMComponents/4AMSubmit/index.scss";
import imageUrl from "content/xm.PNG";
import ReactPlayer from "react-player";
import "content/deriv.PNG";
//import ReactPlayer from 'react-player';


dayjs.extend(weekOfYear);

export default function Submit(productArr) {
  // console.log(productArr.toString());
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dropdownValue, setDropdownValue] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [popupMessage, setPopupMessage] = useState({
    show: false,
    message: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContentType, setModalContentType] = useState("");

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [modalContentType, setModalContentType] = useState("");

  const openModal = (contentType) => {
    setIsModalOpen(true);
    setModalContentType(contentType);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContentType(""); // Optionally reset the content type on close
  };

  useEffect(() => {
    const fetchAvailableSlots = async (day) => {
      setIsLoading(true);
      const db = getFirestore(app);

      const timeRuleCollectionRef = collection(db, "timeRules");
      const timeRuleSnapshot = await getDocs(timeRuleCollectionRef);
      const timeRuleList = timeRuleSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("Time Rules:", timeRuleList);

      const userCollectionRef = collection(db, "user");
      const userSnapshot = await getDocs(userCollectionRef);
      const userList = userSnapshot.docs.map((doc) => {
        const userData = doc.data();
        if (userData.TimeStamp) {
          const userWeek = dayjs(userData.TimeStamp.toDate()).week();
          const userDay = dayjs(userData.TimeStamp.toDate())
            .format("dddd")
            .toLowerCase();
          return { ...userData, week: userWeek, day: userDay };
        } else {
          console.error("User data missing TimeStamp:", userData);
          return { ...userData, week: "Unknown", day: "Unknown" };
        }
      });
      console.log("User List:", userList);

      const selectedWeek = dayjs(selectedDate).week();
      const dayLowerCase = day.toLowerCase();
      const matchingTimeRule = timeRuleList.find(
        (rule) => rule.id.trim().toLowerCase() === dayLowerCase
      );
      console.log("Matching Time Rule for day:", day, matchingTimeRule);

      if (matchingTimeRule) {
        let updatedSlots = { ...matchingTimeRule };
        userList.forEach((user) => {
          if (user.week === selectedWeek && user.day === dayLowerCase) {
            Object.entries(updatedSlots).forEach(([key, slot]) => {
              if (slot.Time === user.prefTime && slot.Slots > 0) {
                slot.Slots -= 1;
              }
            });
          }
        });

        setAvailableSlots(
          Object.entries(updatedSlots).filter(
            ([key, value]) =>
              key !== "id" && (!userList.length || value.Slots > 0)
          )
        );
        console.log("Updated Slots for day:", day, updatedSlots);
      } else {
        setAvailableSlots([]);
      }
      setIsLoading(false);
    };

    if (selectedDate) {
      const day = dayjs(selectedDate).format("dddd").toLowerCase();
      fetchAvailableSlots(day);
    }
  }, [selectedDate]);

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    setDropdownValue("");
  };

  const showPopup = (message) => {
    setPopupMessage({ show: true, message });
    setTimeout(() => {
      setPopupMessage({ show: false, message: "" });
    }, 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      name &&
      surname &&
      cellNumber &&
      email &&
      selectedDate &&
      dropdownValue
    ) {
      setIsLoading(true);
      const db = getFirestore(app);
      const userCollectionRef = collection(db, "user");
      await addDoc(userCollectionRef, {
        TimeStamp: selectedDate.toDate(),
        cellNumber: cellNumber,
        name: name,
        prefTime: dropdownValue,
        products: productArr,
        surname: surname,
      });

      const emailBody = `Welcome to the 4am Club Team.\n\nYour booking is made for ${dayjs(
        selectedDate
      ).format(
        "dddd, MMMM D, YYYY"
      )} at ${dropdownValue}. The tech team will reach out to you shortly before your install date and time.\n\nIf you have any questions or problems please contact the following number on WhatsApp: 076 266 1867\n\nFriendly greetings,\n4AM Team`;
      const mailOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          "X-API-KEY":
            "gyVSp8S64hst65uOiSFZrrcuLQW1XBwMGVsfvF1OCMJLvK8NvZcar8SvexFReUfW6ZubFRXYx8wGWo47zZ6x88CnXluYx3gEx8SxqShakBRcjMhPP8MY3yczIxBgBOG9", // Replace with your actual API key
        },
        body: new URLSearchParams({
          to: email,
          from: "admin@greenfoxinc.co.za",
          subject: "Booking Confirmation",
          body: emailBody,
        }),
      };

      try {
        const response = await fetch(
          "https://api.mailbaby.net/mail/send",
          mailOptions
        );
        const responseData = await response.json();
        console.log("Email sent response:", responseData);
        showPopup("Your booking is set, a confirmation email has been sent to you.");
        setIsLoading(false);
        setFormSubmitted(true);
        openModal('thankYou');
      } catch (error) {
        console.error("Error sending email:", error);
        showPopup("Your booking is set, a conformation.");
      }

      setIsLoading(false);
      setFormSubmitted(true);
    } else {
      showPopup("Please fill all the fields.");
    }
  };

  const handleModalToggle = (contentType = "") => {
    setIsModalOpen(!isModalOpen);
    setModalContentType(contentType);
  };

  return (
    <Box className="submitBox">
      <Box className="submitBoxInner">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <form onSubmit={handleSubmit} className={styles["form-container"]}>
            <div
              className={styles["popup-message"]}
              style={{ display: popupMessage.show ? "block" : "none" }}
            >
              {popupMessage.message}
            </div>

            {/* <Box className="tutorial-container">
                   <div className="tutorial-label">Tutorial Video</div>
               <     ReactPlayer url="https://www.youtube.com/watch?v=uhUBUv5swjg&ab_channel=OryxBotTechSupport" controls className="tutorial-video" />
            </Box> */}

           <Box className="dividerbox">

           </Box>

           <Box className="dividerLabel" >
              Booking Form
           </Box>

           
            <div className={styles["form-content"]}>
              <div className={styles["input-group"]}>
                <TextField
                  required
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  margin="normal"
                  className={styles["input-field"]}
                />
                <TextField
                  required
                  label="Surname"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  margin="normal"
                  className={styles["input-field"]}
                />
              </div>
              <div className={styles["input-group"]}>
                <TextField
                  required
                  label="Cell Number"
                  value={cellNumber}
                  onChange={(e) => setCellNumber(e.target.value)}
                  margin="normal"
                  type="tel"
                  className={styles["input-field"]}
                />
                <TextField
                  required
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                  type="email"
                  className={styles["input-field"]}
                />
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Product"
                  value={productArr.toString()}
                  margin="normal"
                  // className={styles["input-field"]}
                />
              </div>
              <DatePicker
                required
                label="Select a date"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField {...params} className={styles["input-field"]} />
                )}
              />
              {isLoading ? (
                <CircularProgress
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                />
              ) : (
                <FormControl
                  fullWidth
                  margin="normal"
                  required
                  className={styles["input-field"]}
                >
                  <InputLabel id="time-slot-select-label">
                    Available Time Slots
                  </InputLabel>
                  <Select
                    required
                    labelId="time-slot-select-label"
                    id="time-slot-select"
                    value={dropdownValue}
                    label="Available Time Slots"
                    onChange={(e) => setDropdownValue(e.target.value)}
                    className={styles["input-field"]}
                  >
                    {availableSlots.map(([key, slot]) => (
                      <MenuItem
                        key={key}
                        value={slot.Time}
                      >{`${slot.Time} (${slot.Slots} slots available)`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                  
              )}
              <Button
                type="submit"
                variant="contained"
                className={styles["submit-button"]}
                disabled={formSubmitted}
              >
                Submit
              </Button>
            </div>
          </form>
        </LocalizationProvider>

        <Box className="dividerbox">

                  </Box>

      
        {/* <Box className="image-container">
          <div className="image-label">XM Setup</div>
          <img
            src={imageUrl}
            alt="Descriptive Alt Text"
            className="submit-image"
          />
          <Button
            variant="contained"
            className="image-button"
            onClick={() =>
              window.open(
                "https://www.xmza.com/register/profile-account?lang=en&utm_source=4amclub.co.za&utm_content=1214566&utm_medium=affiliate",
                "_blank"
              )
            }
          >
            Setup Now !
          </Button>
        </Box> */}
        {/* <Box className="deriv-setup-container">
          <div className="deriv-setup-label">Deriv Setup</div>
          <img
            src={require("content/deriv.PNG")}
            alt="Deriv Setup"
            className="submit-image"
          />
          <Button
            variant="contained"
            className="image-button"
            onClick={() => window.open("https://deriv.com/signup/", "_blank")}
          >
            Setup Now !
          </Button>
        </Box> */}

        <Dialog
          open={isModalOpen}
          onClose={handleModalToggle}
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            {modalContentType === "xm" && (
              <div>
                <h3>Set up account</h3>
                <div style={{ marginBottom: "20px" }}>
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=4tqKT46xxO8"
                    controls
                  />
                </div>
                <h3>Setup MT</h3>
                <div style={{ marginBottom: "20px" }}>
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=A-wl9eR4Y30"
                    controls
                  />
                </div>
                <Button
                  variant="contained"
                  onClick={() =>
                    window.open(
                      "https://www.xmza.com/register/profile-account?lang=en&utm_source=4amclub.co.za&utm_content=1214566&utm_medium=affiliate",
                      "_blank"
                    )
                  }
                >
                  Go to XM
                </Button>
              </div>
            )}
            {modalContentType === "deriv" && (
              <div>
                <h3>Setup Deriv</h3>
                <div style={{ marginBottom: "20px" }}>
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=zYPiK6YGGbI"
                    controls
                  />
                </div>
                <Button
                  variant="contained"
                  onClick={() =>
                    window.open("https://deriv.com/signup/", "_blank")
                  }
                >
                  Go to Deriv
                </Button>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </Box>


      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{"Thank You!"}</DialogTitle>
        <DialogContent>
          {modalContentType === 'thankYou' && (
            <div>
              <p>Thank you for your booking! Our team will reach out to you shortly.</p>
              <Button onClick={closeModal} color="primary">
                Close
              </Button>
            </div>
          )}
          {/* Other conditions for different content types... */}
        </DialogContent>
      </Dialog>


    </Box>
  );
}
