import "./App.scss";
import { Route, Routes } from "react-router-dom";
import { MembershipTypeProvider } from 'components/4AMComponents/mem/mem.js'; // Adjust the import path as needed
// Import all other components as before


import "./App.scss";

import AMRouting from "setup/routing/4AMRouting";
import Login from "pages/login";
import Dashboard from "pages/Dashboard";
import Landing from "pages/landing";
import Ticket from "pages/Ticket/Ticket";
import AdminDash from "pages/AdminDash/AdminDash";
import Gold from "pages/Gold/Gold"; // Ensure this is correct path
import Plat from "pages/Plat/Plat"; // Ensure this is correct path
import Vip from "pages/VIP/VIP.js"; // Ensure this is correct path
import Submit4AM from "pages/submit4AM";
import Form from "pages/Form/Form";
import Vid from "pages/Vid/Vid";
import Vian from "pages/Vian/Vian";


function App() {
  return (
    <MembershipTypeProvider>
      <Routes>
        <Route index element={<Landing />} />
        <Route path="*" element={<Landing />} />
        <Route element={<AMRouting />}>
          <Route path="/4AMSubmit/" element={<Submit4AM />} />
          <Route path="/Dash" element={<Dashboard />} />
          <Route path="/login4AM" element={<Login />} />
          <Route path="/Form" element={<Form />} />
          <Route path="/submit-success" element={<Vid />} />
          <Route path="/TicketLog" element={<Ticket />} />
          <Route path="/AdminDash" element={<AdminDash />} />
          <Route path="/Gold" element={<Gold />} />
          <Route path="/Plat" element={<Plat />} />
          <Route path="/Vip" element={<Vip />} />
          <Route path="/Vian" element={<Vian />} />
        </Route>
      </Routes>
    </MembershipTypeProvider>
  );
}

export default App;
