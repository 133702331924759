import React from 'react';
import "components/4AMComponents/4AMHeader/index.scss";
import { Box } from "@mui/material";
import AMlogo from "content/AMLogo.png";
import { useMembershipType } from 'components/4AMComponents/mem/mem.js'; // Adjust the import path as needed

export default function AMHeader() {
  const { membershipType } = useMembershipType();
  return (
    <Box className="AMHeader">
      <img src={AMlogo} alt="Logo" className="logo"/>
      <h1 className="amHeaderName">{membershipType}</h1>
    </Box>
  );
}
