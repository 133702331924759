import React, { useState ,useEffect} from 'react';
import 'pages/Gold/Gold.scss';
import { Button, Box, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMembershipType } from 'components/4AMComponents/mem/mem.js'
import xmImage from 'content/xm.PNG';
import swissImage from 'content/swiss.png';
import tradeImage from 'content/tradenation.png'


export default function Vid() {
  const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const { nextStep, productType } = location.state || {};

    const { setMembershipType } = useMembershipType();

  useEffect(() => {
    setMembershipType('Gold Membership V1'); 
  }, [setMembershipType]);
 // State to manage video loading

  
  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleSubmit = () => {
    console.log('Opening XM link...');
    const url = 'https://clicks.pipaffiliates.com/c?c=893925&l=en&p=3901';
    const newWindow = window.open();
    newWindow.opener = null;
    newWindow.location = url;
  };

  const swis = ()=>{

    const url = 'https://dashboard-group.bdswiss.com/register/?campaign=1341&p1=&p2=58930&p3=1z0h3p0p';
    window.open(url, '_blank');

  }

  const handleNextStep = () => {
    if (nextStep && productType) {
        navigate(`/${nextStep}?type=${productType}`);
    }
};


  return (
    <div className='mainDiv'>
      <Box className='box'>

      <Box className='titlebox'>
         How to setup your Discord !
        </Box>

      {isLoading ? (
          <Box className='loadingBox' display="flex" justifyContent="center" alignItems="center">
            <CircularProgress /> 
          </Box>
        ) : null}
        <iframe
          src="https://www.youtube.com/embed/frnwqA_rHMw" 
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          onLoad={handleLoad}
          style={{ display: isLoading ? 'none' : 'block' }}
        />

      <Box className='titlebox'>
         How to setup Copytrading with XM! 
        </Box>

        
        
        
       
        {isLoading ? (
          <Box className='loadingBox' display="flex" justifyContent="center" alignItems="center">
            <CircularProgress /> 
          </Box>
        ) : null}
        <iframe
          src="https://www.youtube.com/embed/oHyW7cGQ7r0" 
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          onLoad={handleLoad}
          style={{ display: isLoading ? 'none' : 'block' }}
        />
        

        <Box className='bbox'> 
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={xmImage} alt="XM" style={{ width: '95px', height: '40px', marginRight: '10px' }} />
          <Button className='button' onClick={handleSubmit}>Set up your xm</Button>
        </div>
      </Box>


        
        {/* <Box className='titlebox'>
         How to Setup Your swiss BDD 
        </Box> */}


        {/* {isLoading ? (
          <Box className='loadingBox' display="flex" justifyContent="center" alignItems="center">
            <CircularProgress /> {/* Show loading indicator */}
          {/* </Box> */}
        {/* ) : null}
          <iframe
            src="https://www.youtube.com/embed/axdeSMsv0f0" // Changed from 'watch?v=' to 'embed/'
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            onLoad={handleLoad}
            style={{ display: isLoading ? 'none' : 'block' }}
          /> */} 


        {/* <Box className='bbox'> 
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={swissImage} alt="Swiss BDD" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
            <Button className='button' onClick={swis}>Set up Swiss BDD</Button>
          </div>
        </Box>*/}

        <Box>
        (If you have followed all the steps in the videos above please move on to step 2)
        </Box> 

      <Box className='tan'>
        <Box className='titlebox'>
          
        Step 2 :

        </Box>
        

        <Box className='bbox '> 
          <Button className='button' onClick={handleNextStep}>Go to step 2</Button>
        </Box>
     </Box>

      </Box>
    </div>
  );
}
