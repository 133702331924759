import React, { createContext, useContext, useState } from 'react';

const MembershipTypeContext = createContext();

export const useMembershipType = () => useContext(MembershipTypeContext);

export const MembershipTypeProvider = ({ children }) => {
  const [membershipType, setMembershipType] = useState('CheatCode');
  return (
    <MembershipTypeContext.Provider value={{ membershipType, setMembershipType }}>
      {children}
    </MembershipTypeContext.Provider>
  );
};
